import React, {FC} from "react";
import {Modal, Button} from "react-bootstrap";
import QRCode from 'react-qr-code';
import {IQrModalProps} from '../../interfaces/Carts'
import {useSelector} from "react-redux";
import {RootState} from "../../components/Store";

const QRModal: FC<IQrModalProps> = ({show, qr, handleClose}) => {

    const currentUser = useSelector((state: RootState) => state).user.detail;

    const myString = process.env.REACT_APP_POS_PROTOCOL + currentUser.domain_prefix + "." + process.env.REACT_APP_POS_URL + qr;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-dialog-scrollable"
        >
            <Modal.Header closeButton>
                <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <QRCode
                    value={myString}
                    size={256}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    style={{margin: '0 auto', display: 'block'}}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QRModal;
