import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import EditModal from "./EditModal";
import AlertModal from "./AlertModal"; // Import the AlertModal component
import DataTable from "react-data-table-component";
import { RootState } from "../../components/Store";
import {useDispatch, useSelector} from "react-redux";
import EditCartModal from "./EditCartModal";
import {ICartData, IProps} from '../../interfaces/Carts';
import {IPourTableData} from '../../interfaces/Pours';
import {setList as cartSetList} from "../../../redux/Cart";
import {DTableData} from "../../defaults/Cart";
import {IUnit} from "../../interfaces/Beverages";
import {DPourData} from "../../defaults/Pour";
import QRModal from "./QRModal";

const Cart: React.FC<IProps> = ({ className }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const cartPourNumber:number = 10;

  const [showModal, setShowModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const [showCartEditModal, setShowCartEditModal] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false); // State for the alert modal

  const [selectedData, setSelectedData] = useState<IPourTableData>(DPourData);

  const [tableData, setTableData] = useState<IPourTableData[]>(DTableData);

  const [loading, setLoading] = useState(true);

  const data = useSelector((state: RootState) => state);

  const fetchData = async () => {

    try {

      const poursList = [];

      for (let i:number = 1; i < cartPourNumber+1 ; i++) {

        if(data.cart.list[0].pours.find((p: any) => p.position === i)) {
          let pour = data.cart.list[0].pours.find((p: any) => p.position === i);
          poursList.push(pour);
        }else{
          let empty = { ...DPourData};
          empty.cart_id = data.cart.list[0].id;
          empty.position = i;
          poursList.push(empty);
        }

      }

      console.log(poursList)

      setTableData(poursList);
      setLoading(false);

    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    fetchData();
  }, [data.cart]);

  const columns = [
    {
      name: "Position #",
      selector: (row: IPourTableData) => "#"+row.position,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row: IPourTableData) => {
        if(row.beverage_id !== -1) {
          let beverageName = data.beverage.list.find((b: any) => b.id === row.beverage_id).name;
          return beverageName;
        }else{
          return "";
        }
      },
      sortable: false,
    },
    {
      name: "Price pre unit",
      selector: (row: IPourTableData) => {
        if(row.price == 0 ) return '';
        return row.price
      },
      sortable: false,
    },
    {
      name: "Capacity",
      selector: (row: IPourTableData) => {
        if(row.current_capacity != -1) {
          return row.current_capacity + "/" + row.full_capacity
        }else{
          return "-/-";
        }
      },
      sortable: false,
    },
    {
      name: "Unit",
      selector: (row: IPourTableData) => {
        if(row.beverage_id !== -1) {
          let unitId = data.beverage.list.find((b: any) => b.id === row.beverage_id).unit_id;
          let unitName = data.beverage.units.find((u: IUnit) => u.id === unitId).name
          return unitName;
        }else{
          return "";
        }
      },
      sortable: false,
    },{
      name: "State",
      selector: (row: IPourTableData) => row.state,
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row: IPourTableData) => {

        if (!row.id) {
          return (
              <div className="d-flex justify-content-end">
                <button
                    className="btn btn-success px-5 py-2 mx-2"
                    onClick={() => {
                      setQrCode("/" + data.cart.list[0].code + "/" + row.position);
                      setShowQrModal(true);
                    }}
                >
                  QR
                </button>
                <button
                    className="btn btn-warning px-5 py-2 mx-2"
                    onClick={() => {
                      setSelectedData(row);
                      setShowModal(true);
                    }}
                >
                  Add
                </button>
              </div>
          );
        }

        return (
            <div className="d-flex justify-content-end">
              <button
                  className="btn btn-success px-5 py-2 mx-2"
                  onClick={() => {
                    setQrCode("/" + data.cart.list[0].code + "/pour/" + row.position);
                    setShowQrModal(true);
                  }}
              >
                QR
              </button>
              <button
                  className="btn btn-warning px-5 py-2 mx-2"
                  onClick={() => {
                    setSelectedData(row);
                    setShowModal(true);
                  }}
              >
                Edit
              </button>
              <button
                  className="btn btn-danger px-5 py-2 mx-2"
                  onClick={() => handleDelete(row.id)}
              >
                Delete
              </button>
            </div>
        );

      },
      ignoreRowClick: true,
      button: true,
      width: "230px",

    },
  ];

  const handleDelete = (id: string) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };

  const handleModalClose = () => setShowModal(false);
  const handleAddModalClose = () => setShowCartEditModal(false);

  const handleQrModalClose = () => setShowQrModal(false);
  const handleAlertModalClose = () => setShowAlertModal(false); // Close alert modal

  const handleSave = (updatedData: IPourTableData) => {

    const updatedTableData = tableData.map((item) =>
      item.position === updatedData.position ? updatedData : item
    );

    setTableData(updatedTableData);
    setShowModal(false);

  };

  const handleAdd = (newData: IPourTableData) => {
    if (tableData.length < 10) {
      const newTableData = [...tableData, newData];
      setTableData(newTableData);
      setShowCartEditModal(false);
    } else {
      setShowAlertModal(true); // Show alert modal if limit is reached
    }
  };

  const handleEditCart = (newData: ICartData) => {

      dispatch(cartSetList([newData]));
      setShowCartEditModal(false);

  };

  if(data.cart.list.length == 0){
    return (<div></div>);
  }

  return (
    <>

      <EditModal
        show={showModal}
        handleClose={handleModalClose}
        tableData={selectedData}
        cartData={data.cart.list[0]}
        handleSave={handleSave}
      />

      <EditCartModal
        show={showCartEditModal}
        handleClose={handleAddModalClose}
        handleEdit={handleEditCart}
        cartData={data.cart.list[0]}
      />

      <QRModal
          show={showQrModal}
          handleClose={handleQrModalClose}
          qr={qrCode}
      />

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              {data.cart.list[0].name}
              <span className="text-muted mt-1 fw-semibold"> #{data.cart.list[0].code}</span>{" "}
            </span>
            <a
              href={"https://www.google.com/maps/@"+data.cart.list[0].log+","+data.cart.list[0].lat+",11.39z?entry=ttu"}
              target="_blank"
              className="mt-1 fw-semibold fs-7"
              rel="noreferrer"
            >
              {data.cart.list[0].address} →
            </a>
          </h3>
          <button
            className="btn btn-warning mt-3"
            onClick={() => setShowCartEditModal(true)}
          >
            Edit Cart
          </button>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <DataTable
              columns={columns}
              data={tableData}
              progressPending={loading}
              keyField="position"
            />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export { Cart };
