/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {StatisticsWidget1} from "../../../_metronic/partials/widgets/statistics/StatisticsWidget1";
import {
    MixedWidget10,
    MixedWidget11,
} from "../../../_metronic/partials/widgets";
import {useSelector} from "react-redux";
import {RootState} from "../../components/Store";
import {all as getAllTransactions} from "../../components/requests/Transaction"
import DataTable from "react-data-table-component";
import {TableTransactions} from "../../interfaces/Transaction";

const DashboardPage: FC = () => {

    const [dateFrom, setDateFrom] = useState<Date | null>(new Date());
    const [dateTo, setDateTo] = useState<Date | null>(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState<string>("");
    const [selectedCart, setSelectedCart] = useState<string>("");
    const [tableData, setTableData] = useState<TableTransactions[]>([]);
    const [loading, setLoading] = useState(true);

    const totalPrice = 0;
    const totalTax = 0;
    const totalQtyUsed = 0;

    const data = useSelector((state: RootState) => state);

    // Handle period change
    const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPeriod(event.target.value);

        if (event.target.value === "weekly") {
            const today = new Date();
            const startOfWeek1 = new Date(today.setDate(today.getDate() - today.getDay()));
            setDateFrom(startOfWeek1);
            setDateTo(new Date());
        } else if (event.target.value === "monthly") {
            const today = new Date();
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 30);
            setDateFrom(startOfMonth);
            setDateTo(endOfMonth);
        } else if (event.target.value === "daily") {
            setDateFrom(new Date());
            setDateTo(new Date());
        } else if (event.target.value === "") {
            setDateFrom(new Date());
        }
        // Add more logic for other periods if necessary
    };

    // Handle cart selection change
    const handleCartChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCart(event.target.value);
    };

    const handleChangeDateFrom = (date: any) => {
        setDateFrom(new Date(date));
        if (selectedPeriod !== "") {
            setDateTo(new Date(date));
        }
    };

    const sendForm = () => {

        getAllTransactions({
            "dateFrom": dateFrom,
            "dateTo": dateTo,
            "selectedCart": selectedCart
        }).then(data => {

            try {

                const formattedData = data.transactions.map((item: any) => ({
                    id: item.id,
                    date: item.created_at,
                    name: item.beverage_name,
                    beverage_name: item.beverage_name,
                    price: item.totalPrice,
                    qty: item.qty,
                    qtyUsed: (item.qtyUsed) ? item.qtyUsed : 0,
                    unitName: item.unitName,
                    paymentCode: item.payment_method,
                    payment_amount: item.payment_amount,
                    tax: item.tax,
                    unit: item.unit_id,
                }));

                setTableData(formattedData);
                setLoading(false);

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }

        }).catch(error => {
            console.error('Error occurred:', error);
        });

    }

    const columns = [
        {
            name: "#ID",
            selector: (row: TableTransactions) => (row.id) ? "#"+row.id : row.name,
            sortable: true,
            width: '100px',
        },
        {
            name: "Date",
            selector: (row: TableTransactions) => (row.date) ? new Date(row.date).toLocaleString('en-US') : '',
            sortable: true,
            width: '200px',
        },
        {
            name: "Beverage",
            selector: (row: TableTransactions) => row.beverage_name,
            sortable: true,
        },
        {
            name: "Total price",
            selector: (row: TableTransactions) => row.price.toFixed(2),
            sortable: true,
            className: "table-column-price",
        },

        {
            name: "Tax",
            selector: (row: TableTransactions) => row.tax.toFixed(2),
            sortable: true,
            className: "table-column-price",
        },

        {
            name: "Qty (Unit)",
            selector: (row: TableTransactions) => row.qtyUsed + " / " +row.qty + " (" + row.unitName + ") ",
            sortable: true,
            className: "table-column-price",
        },
    ];

    useEffect(() => {
        sendForm();
    }, []);

    return (
        <>



            {/* begin::Row */}
            <div className="card mb-5 mb-xl-5">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-column flex-wrap flex-sm-nowrap mb-3">
                        <div className="px-7 py-5">
                            <div className="fs-5 text-gray-900 fw-bolder">Filter Options</div>
                        </div>
                        <div className="row px-7 py-5 d-flex justify-content-between align-items-center">

                            {/* Cart Selector */}
                            <div className="col-xl-2 col-sm-12">
                                <label className="form-label fw-bold">Cart:</label>
                                <div>
                                    <select
                                        className="form-select form-select-solid"
                                        value={selectedCart}
                                        onChange={handleCartChange}
                                        data-kt-select2="true"
                                        data-placeholder="Select option"
                                        data-allow-clear="true"
                                    >
                                        <option value=""></option>
                                        {data.cart.list.map((cart: any) => (
                                            <option key={cart.id} value={cart.id}>
                                                {cart.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Period Selector */}
                            <div className="col-xl-2">
                                <label className="form-label fw-bold">Period:</label>
                                <div>
                                    <select
                                        className="form-select form-select-solid"
                                        value={selectedPeriod}
                                        onChange={handlePeriodChange}
                                        data-kt-select2="true"
                                        data-placeholder="Select option"
                                        data-allow-clear="true"
                                    >
                                        <option value="">Custom</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>


                            <div className="col-xl-2">
                                <label className="form-label fw-bold">Select Date From</label>
                                <div>
                                    <DatePicker
                                        selected={dateFrom}
                                        onChange={(date) => handleChangeDateFrom(date)}
                                        showWeekNumbers={selectedPeriod === "weekly"}
                                        useWeekdaysShort={selectedPeriod === "weekly"}
                                        showMonthYearPicker={selectedPeriod === "monthly"}
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            {selectedPeriod === "" ? (

                                <>
                                    <div className="col-xl-2">
                                        <label className="form-label fw-bold">Select Date To</label>
                                        <div>
                                            <DatePicker
                                                selected={dateTo}
                                                onChange={(date) => setDateTo(date)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </>


                            ) : (
                                <>
                                    <div className="col-xl-2">
                                        <label className="form-label fw-bold">Select Date To</label>
                                        <div>
                                            <DatePicker
                                                disabled={true}
                                                selected={dateTo}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Buttons */}
                            <div className="col-xl-2 d-flex justify-content-end">
                                <button
                                    type="reset"
                                    className="btn btn-sm btn-light btn-active-light-primary me-2"
                                >
                                    Reset
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                    onClick={sendForm}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row mb-5">
                <div className="col-xxl-12">
                    <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        keyField="id"
                        pagination
                    />
                </div>
            </div>

        </>
    );

}

const DashboardWrapper: FC = () => {
    const intl = useIntl();
    const [startDate, setStartDate] = useState<Date | null>(new Date());

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: "MENU.DASHBOARD"})}
            </PageTitle>
            <DashboardPage/>
        </>
    );
};

export {DashboardWrapper};
