import exp from "node:constants";


export const DPourData = {
    id: "",
    name: "",
    position: -1,
    state: "",
    price: 0,
    unit: "",
    beverage_id: -1,
    cart_id: -1,
    full_capacity: 0,
    current_capacity: -1
}
