import exp from "node:constants";


export const DDrink = {
    id: "",
    name: "",
    price: "",
    unit: 0,
    existingLogoUrl: "",
    logo: ""
}

export const DInitialFormData = {
    id: "",
    name: "",
    price: "",
    unit: 0,
    existingLogoUrl: "",
    logo: "",
};


