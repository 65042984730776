import axios from 'axios';
export const URL = `${process.env.REACT_APP_API_URL}/pours`;

function makeJsonForRequest(postData: any){

    return {
        name: postData.name,
        position: postData.position,
        state: postData.state || '',
        price: postData.price || '',
        cart_id: postData.cart_id || '',
        unit: postData.unit ? parseFloat(postData.unit) : undefined,
        full_capacity: postData.full_capacity ? parseFloat(postData.full_capacity) : undefined,
        current_capacity: postData.current_capacity ? parseFloat(postData.current_capacity) : parseFloat(postData.full_capacity),
        beverage_id: postData.beverage_id || null
    };

}
export function addPour(postData: any) {
    return axios.post(URL, makeJsonForRequest(postData)).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });

}
export function updatePour(postData: any) {

    return axios.put(URL + '/' + postData.id, makeJsonForRequest(postData)).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });

}
export function deleteP(postDataId: string) {

    return axios.delete(URL + '/' + postDataId, {})
        .then(response => {
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        });

}
