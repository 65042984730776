import axios from 'axios';
export const BEVERAGE_URL = `${process.env.REACT_APP_API_URL}/beverage`;

function makeRequestData(postData: any,logo:any){

    const formData = new FormData();
    formData.append('logo', logo);
    formData.append('name', postData.name);
    formData.append('price', postData.price);
    formData.append('unit_id', postData.unit);

    return formData;

}

export function add(postData: any,logo:any) {

    const formData = makeRequestData(postData, logo);

    return axios.post(BEVERAGE_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then(response => {
        console.log(response.data);
    })
        .catch(error => {
            console.error(error);
        });

}

export function update(postData: any,logo:any) {

    const formData = makeRequestData(postData, logo);

    return axios.post(BEVERAGE_URL + '/' + postData.id + "?_method=PUT", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then(response => {
        console.log(response.data);
    })
        .catch(error => {
            console.error(error);
        });
}

export function deleteB(postDataId: string) {
    return axios.delete(BEVERAGE_URL + '/' + postDataId, {}).then(response => {
        console.log(response.data);
    })
        .catch(error => {
            console.error(error);
        });
}
